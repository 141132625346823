import { css } from 'styled-components/macro';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import GlobalStyles from 'styles/GlobalStyles';
import Cars from './components/cars';
import HotelTravelLandingPage from './demos/HotelTravelLandingPage';
import ContactUs from './pages/ContactUs';
import AboutUs from './pages/AboutUs';
import CarDetails from './components/cars/CarDetails';
import { CarContext } from './context/carcontext'; //eslint-disable-line

export default function App() {
  const [filteredVehicles, setFilteredVehicles] = useState();
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          'https://rcdbil.accesspaket.bytbilcms.com/wp-json/accesspackage/v1/cars',
        );
        const data = await response.json();
        setData(data);
        setFilteredVehicles(data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  function formatPrice(price) {
    const formattedNumber = price
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `${formattedNumber} kr`;
  }

  return (
    <>
      <GlobalStyles />
      <Router>
        <CarContext.Provider value={filteredVehicles}>
          <Routes>
            <Route
              path="/"
              element={
                <HotelTravelLandingPage
                  data={data.slice(0, 6)}
                  formatPrice={formatPrice}
                />
              }
            />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route
              path="/cars"
              element={
                <Cars
                  setFilteredVehicles={setFilteredVehicles}
                  filteredVehicles={filteredVehicles}
                  data={data}
                  formatPrice={formatPrice}
                />
              }
            />
            <Route
              path="/cars/:car_id"
              element={<CarDetails formatPrice={formatPrice} />}
            />
          </Routes>
        </CarContext.Provider>
      </Router>
    </>
  );
}
