import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { Subheading as SubheadingBase } from 'components/misc/Headings.js';

const Container = styled.div`
  ${tw`relative max-w-screen-xl mx-auto`}
  height: calc(100vh - 332px);
  display: flex;
  align-items: center; /* Vertically align content */
  justify-content: center; /* Horizontally align content */
`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between items-center py-20 md:py-24`;
const Column = tw.div`w-full md:max-w-md mx-auto md:max-w-none`;
const ImageColumn = tw(Column)`md:w-1/2 flex-shrink-0 h-80 md:h-auto md:mr-16`;
const TextColumn = tw(Column)`md:w-1/2`;

const Image = styled.img`
  ${tw`rounded h-full mx-auto`}
`;

const TextContent = tw.div`text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-primary-300`;
const Heading = tw.h1`font-bold text-3xl sm:text-4xl lg:text-5xl text-primary-300 leading-tight`;
const Description = tw.p`mt-4 text-sm md:text-base lg:text-lg font-medium leading-relaxed text-primary-500`;

export default () => {
  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image
            src={
              'https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80'
            }
          />
        </ImageColumn>
        <TextColumn>
          <TextContent>
            <Subheading>Om oss</Subheading>
            <Heading>Service i världsklass</Heading>
            <Description>
              Vi skapar tillit genom ärlighet och försöker alltid uppnå våra
              kunders förväntningar. Med massa erfarenhet i nacken hittar vi
              alltid en lösning till er som passar era behov.
            </Description>
            <Description>
              Hos oss är kontinuerlig förbättring en del av vår kärnverksamhet.
              Vi strävar ständigt efter att överträffa era förväntningar och
              skapa en trygg och tillfredsställande bilhandelsupplevelse.
            </Description>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
