import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { FaInstagram, FaFacebook } from 'react-icons/fa';

import { ReactComponent as FacebookIcon } from 'images/facebook-icon.svg';
import { ReactComponent as YoutubeIcon } from 'images/youtube-icon.svg';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../images/rcd_logo.svg';
import { LogoLink } from '../headers/light';

const Container = tw.div`relative bg-gray-600 text-gray-100 -mx-8 -mb-8 px-8`;
const Content = tw.div`max-w-screen-xl mx-auto pt-16 pb-8`;
const FiveColumns = tw.div`flex flex-wrap justify-between`;

const Column = tw.div`w-1/2 md:w-1/6 mb-8 md:mb-0 text-sm sm:text-base text-center md:text-left`;
const CompanyColumn = tw.div`text-center md:text-left mb-16 lg:mb-0 w-full lg:w-1/5`;

const ColumnHeading = tw.h5`font-bold uppercase`;

const LinkList = tw.ul`mt-1 text-sm font-medium`;
const LinkListItem = tw.li`mt-2`;
const ULink = tw(
  Link,
)`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-100 pb-1 transition duration-300`;
const LogoText = tw.h5`ml-2 text-xl font-black`;

const CompanyAddress = tw.p`mt-1 max-w-xs font-medium text-sm mx-auto lg:mx-0 lg:mr-4 leading-loose text-center lg:text-left`;

const SocialLinksContainer = tw.div`mt-4 text-center lg:text-left`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-500 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-6 h-6 text-primary-500`}
  }
`;

const CopyrightAndCompanyInfoRow = tw.div`pb-0 text-sm font-normal flex flex-col sm:flex-row justify-between items-center`;
const CopyrightNotice = tw.div``;
const CompanyInfo = tw.div``;
const Divider = tw.div`my-8 border-b-2 border-gray-300`;
export default () => {
  return (
    <Container>
      <Content>
        <FiveColumns>
          <CompanyColumn>
            <LogoLink href="/">
              <Logo />
            </LogoLink>
            <SocialLinksContainer>
              <SocialLink
                href="https://www.facebook.com/Rcdbilab-363465850794032/"
                target="_blank"
              >
                <FaFacebook />
              </SocialLink>
              <SocialLink href="https://www.instagram.com/rcdbilab/">
                <FaInstagram />
              </SocialLink>
            </SocialLinksContainer>
          </CompanyColumn>
          <Column>
            <ColumnHeading>Address</ColumnHeading>
            <CompanyAddress>RCD Bil AB</CompanyAddress>
            <CompanyAddress>Västbergavägen 24</CompanyAddress>
            <CompanyAddress>126 30 Hägersten</CompanyAddress>
            <LinkList>
              <LinkListItem>
                <Link href="mailto:info@rcdbil.se">info@rcdbil.se</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Mer information</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <ULink to="/cars">Köpa bil</ULink>
              </LinkListItem>
              <LinkListItem>
                <ULink to="/contact">Sälja bil</ULink>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Snabb länkar</ColumnHeading>
            <LinkList>
              {/*TODO ordna en FAQ sida*/}
              {/*<LinkListItem>*/}
              {/*  <ULink to="/">FAQs</ULink>*/}
              {/*</LinkListItem>*/}
              <LinkListItem>
                <ULink to="/contact">Kontakt</ULink>
              </LinkListItem>
              <LinkListItem>
                <ULink to="/contact">Om oss</ULink>
              </LinkListItem>
            </LinkList>
          </Column>
        </FiveColumns>
        <Divider />
        <CopyrightAndCompanyInfoRow>
          <CopyrightNotice>
            &copy; 2023 RCD Bil AB. All Rights Reserved.
          </CopyrightNotice>
          <CompanyInfo>
            Det självklara valet när du vill köpa en bil.
          </CompanyInfo>
        </CopyrightAndCompanyInfoRow>
      </Content>
    </Container>
  );
};
