import { Container, ContentWithPaddingXl } from 'components/misc/Layouts';
import React from 'react';

const RecoWidget = () => {
  const src =
    'https://widget.reco.se/v2/widget/4026278?mode=HORIZONTAL_QUOTE&inverted=false&border=false';
  const height = 225;

  return (
    <Container>
      <ContentWithPaddingXl>
        <iframe
          src={src}
          title="RCD Bil AB - Omdömen på Reco"
          height={height}
          style={{
            width: '100%',
            border: '0',
            display: 'block',
            overflow: 'hidden',
          }}
          data-reactroot=""
        />
      </ContentWithPaddingXl>
    </Container>
  );
};

export default RecoWidget;
