import { useEffect } from 'react';

export function ChatWidget() {
  useEffect(() => {
    // Initialize CRISP
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = '1af3ee17-ce9f-4815-ab5a-7dc8cb47582f';

    // Load Crisp script
    const script = document.createElement('script');
    script.src = 'https://client.crisp.chat/l.js';
    script.async = true;
    document.head.appendChild(script);

    // Configure Crisp
    script.onload = () => {
      window.$crisp.push(['set', 'message:text', ' ']);
      window.$crisp.push(['set', 'user:nickname', ['Visitor']]);
      window.$crisp.push(['set', 'chat:theme', 'blue']);
      window.$crisp.push([
        'set',
        'message:placeholder',
        'Type your message...',
      ]);
    };

    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
      delete window.$crisp;
      delete window.CRISP_WEBSITE_ID;
    };
  }, []);

  return null;
}
