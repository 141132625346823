import React from 'react';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import HeaderBase, {
  NavLink as NavLinkBase,
  NavLinks,
  PrimaryLink as PrimaryLinkBase,
} from 'components/headers/light.js';
import Footer from 'components/footers/MiniCenteredFooter.js';
import MainFeature1 from 'components/features/TwoColWithButton.js';
import TeamCardGrid from 'components/cards/ProfileThreeColGrid.js';
import RecoWidget from 'components/iFrames/RecoWidget';

const Subheading = tw.span`uppercase tracking-wider text-sm text-primary-500`;
const HeaderContainer = tw.div`max-w-none -mt-8 py-8 -mx-8 px-8 bg-gray-600`;
const Header = tw(HeaderBase)``;
const NavLink = tw(
  NavLinkBase,
)`lg:text-gray-100 lg:hocus:text-gray-300 lg:hocus:border-gray-100`;
const PrimaryLink = tw(
  PrimaryLinkBase,
)`shadow-raised lg:bg-primary-300 lg:hocus:bg-primary-500`;

export default () => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/">Hem</NavLink>
      <NavLink href="/cars">Köpa bil</NavLink>
      <NavLink href="/contact">Sälja bil</NavLink>
      <PrimaryLink href="/contact">Kontakt</PrimaryLink>
    </NavLinks>,
  ];
  return (
    <AnimationRevealPage>
      <HeaderContainer>
        <Header links={navLinks} />
      </HeaderContainer>
      <MainFeature1
        subheading={<Subheading>Om oss</Subheading>}
        heading="Service i världsklass"
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <RecoWidget />
      {/* <TeamCardGrid subheading={<Subheading>Vårt team</Subheading>} /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
