import React from 'react';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import HeaderBase, {
  NavLink as NavLinkBase,
  NavLinks,
  PrimaryLink as PrimaryLinkBase,
} from 'components/headers/light.js';
import Footer from 'components/footers/MiniCenteredFooter.js';
import ContactUsForm from 'components/forms/TwoColContactUsWithIllustrationFullForm.js';
import RecoWidget from 'components/iFrames/RecoWidget';

const HeaderContainer = tw.div`max-w-none -mt-8 py-8 -mx-8 px-8 bg-gray-600`;
const Header = tw(HeaderBase)``;
const NavLink = tw(
  NavLinkBase,
)`lg:text-gray-100 lg:hocus:text-gray-300 lg:hocus:border-gray-100`;
const PrimaryLink = tw(
  PrimaryLinkBase,
)`shadow-raised lg:bg-primary-300 lg:hocus:bg-primary-500`;

export default () => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/">Hem</NavLink>
      <NavLink href="/cars">Köpa bil</NavLink>
      <NavLink href="/about">Om oss</NavLink>
      <PrimaryLink href="/contact">Kontakt</PrimaryLink>
    </NavLinks>,
  ];
  return (
    <AnimationRevealPage>
      <HeaderContainer>
        <Header links={navLinks} />
      </HeaderContainer>
      <ContactUsForm />
      <RecoWidget />
      <Footer />
    </AnimationRevealPage>
  );
};
