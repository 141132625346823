import React from 'react';
import { motion } from 'framer-motion';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts.js';
import { SectionHeading } from 'components/misc/Headings.js';
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js';

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;

const TabContent = tw(
  motion.div,
)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(
  motion.a,
)`bg-gray-100 rounded-b block max-w-xs mx-auto shadow hover:shadow-lg sm:max-w-none sm:mx-0`;

const CardImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url('${props.imageSrc}');
    `}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

const CardText = tw.div`p-4 text-primary-500`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-400`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;
const Divider = tw.hr`border-none h-[2px] bg-gray-400 my-5`;

export default ({
  onSelectItem,
  heading = 'Våra bilar',
  data,
  formatPrice,
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderRow>
          <Header>{heading}</Header>
        </HeaderRow>
        <TabContent>
          {data?.map(({ data: car, title, id }) => {
            const {
              permalink,
              thumbnailUrl,
              make,
              model,
              modelYear,
              milage,
              gearBox,
              fuel,
              currentPrice,
            } = car;

            return (
              <CardContainer onClick={() => onSelectItem(id)} key={id}>
                <Card
                  className="group"
                  href={permalink}
                  initial="rest"
                  whileHover="hover"
                  animate="rest"
                >
                  <CardImageContainer imageSrc={thumbnailUrl}>
                    <CardHoverOverlay
                      variants={{
                        hover: {
                          opacity: 1,
                          height: 'auto',
                        },
                        rest: {
                          opacity: 0,
                          height: 0,
                        },
                      }}
                      transition={{ duration: 0.3 }}
                    >
                      <CardButton>Köp nu</CardButton>
                    </CardHoverOverlay>
                  </CardImageContainer>
                  <CardText>
                    <CardTitle>
                      {make} {model}
                    </CardTitle>
                    <CardContent>
                      {modelYear} / {milage} / {gearBox} / {fuel}
                    </CardContent>
                    <Divider />
                    <CardPrice>{formatPrice(currentPrice)}</CardPrice>
                  </CardText>
                </Card>
              </CardContainer>
            );
          })}
        </TabContent>
      </ContentWithPaddingXl>
    </Container>
  );
};
