import React from 'react';
import { useNavigate } from 'react-router-dom';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import Hero from 'components/hero/BackgroundAsImage.js';
import MainFeature from 'components/features/TwoColSingleFeatureWithStats.js';
import SliderCard from 'components/cards/ThreeColSlider.js';
import FAQ from 'components/faqs/SingleCol.js';
import Footer from 'components/footers/MiniCenteredFooter.js';
import RecoWidget from 'components/iFrames/RecoWidget';
import ChatBox from '../components/chattbox/index';
import { SpeedInsights } from '@vercel/speed-insights/react';

export default ({ data, formatPrice }) => {
  const navigate = useNavigate();

  const handleSelectItem = (id) => {
    navigate(`/cars/${id}`);
  };
  return (
    <AnimationRevealPage>
      <Hero />
      <SliderCard
        onSelectItem={handleSelectItem}
        formatPrice={formatPrice}
        data={data}
      />
      {/* <Features
        heading="Våra principer"
        description="Vi försäkrar kundernas trygghet genom att erbjuda underhåll, garanti och trygghetsförsäkringar. Hos oss köper ni ett komplett paket som tar hand om er även efter affären."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: 'SUPPORT',
            description:
              'Räkna med support dygnet runt. Med full support och kunskap ser vi till att skräddarsy en affär efter era behov.',
          },
          {
            imageSrc: ShieldIconImage,
            title: 'TRYGGHET',
            description:
              'Vi håller vårat ord och ni som kunder åker härifrån obekymrade.',
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: 'ÄRLIGHET',
            description:
              'Genom att alltid vara ärliga mot våra kunder skapar vi tillit och förtroende.',
          },
        ]}
        linkText=""
      /> */}
      <MainFeature />
      <RecoWidget />
      <FAQ />
      <ChatBox />
      <Footer />
      <SpeedInsights />
    </AnimationRevealPage>
  );
};
