import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../footers/MiniCenteredFooter';
import AnimationRevealPage from '../../helpers/AnimationRevealPage';
import { years, colors, bodyTypes, fuelTypes, brands } from './constants';
import Hero from '../hero/TwoColumnWithPrimaryBackground';
import TabCardGrid from '../cards/TabCardGrid';

const Cars = React.memo(
  ({ setFilteredVehicles, filteredVehicles, data, formatPrice }) => {
    const navigate = useNavigate();

    const handleFilter = (filteredResult) => {
      setFilteredVehicles(filteredResult);
    };

    const handleSelectItem = (id) => {
      navigate(`/cars/${id}`);
    };

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    return (
      <AnimationRevealPage>
        <Hero
          data={data}
          years={years}
          colors={colors}
          bodyTypes={bodyTypes}
          fuelTypes={fuelTypes}
          brands={brands}
          onFilter={handleFilter}
        />
        <TabCardGrid
          onSelectItem={handleSelectItem}
          data={filteredVehicles}
          formatPrice={formatPrice}
        />
        <Footer />
      </AnimationRevealPage>
    );
  },
);

export default Cars;
