import React, { useState } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from 'components/misc/Headings.js';
import { SectionDescription } from 'components/misc/Typography.js';
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts.js';
import { ReactComponent as ChevronDownIcon } from 'feather-icons/dist/icons/chevron-down.svg';
import { ReactComponent as SvgDecoratorBlob1 } from 'images/svg-decorator-blob-7.svg';
import { ReactComponent as SvgDecoratorBlob2 } from 'images/svg-decorator-blob-8.svg';

const Subheading = tw(SubheadingBase)`mb-4 text-center text-primary-500`;
const Heading = tw(SectionHeading)`w-full text-primary-500`;
const Description = tw(SectionDescription)`w-full text-center`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-primary-500 hover:bg-gray-500 transition duration-300 `;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold text-customBg`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300 text-white`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(
  tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`,
);

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default ({
  subheading = "FAQ'S",
  heading = 'Har ni några frågor?',
  description = 'Skriv gärna till oss eller ring oss för mer information.',
  faqs = [
    {
      question: 'Hur köper jag bil via RCD Bil?',
      answer:
        'De flesta av våra kunder har redan hittat en intressant bil i vår vagnpark innan de besöker oss. Om inte, hjälper vi gärna till att hitta en bil enligt dina önskemål. Ni kan köpa en bil av oss via telefon, mail eller besöka oss direkt i vår bilhall.',
    },
    {
      question: 'Hur är det med besiktning och skatt?',
      answer:
        'Vi besiktar alltid de bilar som är inom sin besiktningsperiod. Samtliga bilar hos oss är avställda och ställs på i samband med ditt bilköp. Därför kan den kvarvarande skatten eller eventuell påställningsavgift överlåtas till bilens nye ägare.',
    },
    {
      question: 'Kan jag byta in min bil?',
      answer:
        'Ja, vi börjar med att värdera din nuvarande bil. Därefter får du en rekommendation på bilens värde samt bäst sätt att tillgå för just dig och din bil.',
    },
    {
      question: 'Vilka bilar köper ni in?',
      answer:
        'Vi köper in dem flesta bilar och vårt största fokus är svensksålda bilar under tolv tusen mil. För mer information om just din bil, kontakta vår inköpsavdelning.',
    },
  ],
}) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = (questionIndex) => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container>
      <ContentWithPaddingXl>
        <Column>
          <HeaderContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
          </HeaderContent>
          <FAQSContainer>
            {faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 },
                    }}
                    initial="collapsed"
                    animate={
                      activeQuestionIndex === index ? 'open' : 'collapsed'
                    }
                    transition={{
                      duration: 0.02,
                      ease: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    <ChevronDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: {
                      opacity: 1,
                      height: 'auto',
                      marginTop: '16px',
                      color: 'white',
                    },
                    collapsed: { opacity: 0, height: 0, marginTop: '0px' },
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? 'open' : 'collapsed'}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  {faq.answer}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
