import React from 'react';
import { ChatWidget } from './ChatWidget';
import { FloatingChatButton } from './FloatingChattButton';

export default function App() {
  return (
    <div>
      <FloatingChatButton />
      <ChatWidget />
    </div>
  );
}
