import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { SectionHeading } from 'components/misc/Headings.js';
import RCD from '../../images/volvo.jpeg';

const Container = tw.div`relative border-2 border-customBlue rounded bg-customGray px-12`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(
  Column,
)`md:w-6/12 lg:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-6/12 mt-8 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-8 lg:mr-16 md:order-first`
    : tw`md:ml-8 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  `background-repeat: no-repeat;`,
  tw`rounded bg-contain bg-center h-full`,
]);

const TextContent = tw.div`lg:py-8`;
const Heading = tw(
  SectionHeading,
)`text-left text-3xl sm:text-4xl lg:text-3xl text-center md:text-left leading-tight text-primary-500`;
const Description = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-white mt-4 text-primary-500`;

const Statistics = tw.div`mt-6 lg:mt-8 xl:mt-16 flex flex-wrap`;
const Statistic = tw.div`text-lg sm:text-2xl lg:text-2xl w-1/2 mt-4 lg:mt-10 text-center md:text-left`;
const Value = tw.div`font-bold text-primary-500`;
const Key = tw.div`font-medium text-gray-700`;

export default ({ textOnLeft = false }) => {
  const statistics = [
    {
      key: 'Bilar sålda',
      value: '1965',
    },
  ];

  return (
    <Container style={{ maxWidth: '1280px', margin: '0 auto' }}>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={RCD} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>Vi strävar efter den bästa servicen.</Heading>
            <Description>
              På RCD Bil bygger vi relationer med våra kunder, vi anpassar
              köpprocessen efter era specifika behov. Vi är säkra på att vi
              erbjuder personlig service som andra inte kan matcha.
            </Description>
            <Description>
              Med kunskap och erfarenhet av tidigare affärer erbjuder vi
              detaljerad information om bilens egenskaper, specifikation och
              fördelar som hjälper er att hitta den bil som möter era behov.{' '}
            </Description>
            <Statistics>
              {statistics.map((statistic, index) => (
                <Statistic key={index}>
                  <Value>{statistic.value}</Value>
                  <Key>{statistic.key}</Key>
                </Statistic>
              ))}
            </Statistics>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
