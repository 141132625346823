import React, {useState} from 'react';
import tw from 'twin.macro';
import styled, {keyframes} from 'styled-components';
import {css} from 'styled-components/macro'; //eslint-disable-line
import {
    SectionHeading,
    Subheading as SubheadingBase,
} from 'components/misc/Headings.js';
import {PrimaryButton as PrimaryButtonBase} from 'components/misc/Buttons.js';
import EmailIllustrationSrc from 'images/contact.jpg';
import {useForm} from 'react-hook-form';
import emailjs from '@emailjs/browser';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-lg mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
    tw`md:w-7/12 mt-16 md:mt-0`,
    props.textOnLeft
        ? tw`md:mr-12 lg:mr-16 md:order-first`
        : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left text-gray-500`;
const Heading = tw(
    SectionHeading,
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight text-primary-500`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-700`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 p-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Textarea = styled(Input).attrs({as: 'textarea'})`
    ${tw`h-24`}
`;

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const Loader = styled.div`
    border: 4px dashed rgba(113, 128, 150, 1); /* Light grey */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: ${spin} 2s linear infinite;
`;

const Spinner = () => {
    return <Loader/>;
};

const SubmitButton = styled(PrimaryButtonBase)`
    ${tw`inline-block mt-8 flex justify-center items-center`}
`;

const ContactContainer = styled.div`
    border-radius: 20px;
    ${tw`bg-gray-600 text-white p-8 mb-16`}
`;

const ContactInnerContainer = tw.div`max-w-4xl mx-auto`;
const ContactHeading = tw.h1`text-3xl font-bold`;
const ContactText = tw.p`mt-2`;
const ContactDetails = tw.div`mb-8`;

const ContactSection = styled.div`
    border: 2px dashed #e63332;
    border-radius: 20px;
    ${tw`text-gray-100 p-8 mt-8`}
`;
const ContactLink = tw.a`text-blue-400 hover:text-blue-300 mt-2 inline-block`;
const ContactAddress = tw.address`not-italic mt-4`;

const List = tw.ul`grid grid-cols-2 gap-4`;
const ListItem = tw.li`mb-2`;
const ContactInfo = styled.p`
    ${tw`mt-2`}
`;

const Avvikande = styled(ContactInfo)`
    border-bottom: 2px dashed #e63332;
    margin: 2rem 0;
`;
const BulletPoint = tw.li`text-gray-300 mb-2`;

export default ({
                    subheading = 'Kontakt',
                    heading = 'Hör gärna av dig till oss',
                    description = 'När du vill sälja din bil gör vi en kostnadsfri värdering och därefter tar vi en närmare titt på bilen när den fysiskt finns på plats.',
                    submitButtonText = 'Skicka',
                    textOnLeft = true,
                }) => {
    const [status, setStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
    const {
        register,
        formState: {errors},
        handleSubmit,
        reset,
    } = useForm({
        defaultValues: {
            name: '',
            email: '',
            subject: '',
            number: '',
            message: '',
        },
    });
    const onSubmit = (data) => {

        setIsLoading(true);
        emailjs
            .send('service_zptea1u', 'template_p1ass2y', data, 'ZsOLYmJMqzIy8-818')
            .then(
                (response) => {
                    setStatus(response?.text);
                    reset({
                        name: '',
                        email: '',
                        number: '',
                        message: '',
                        subject: '',
                    });
                    setIsLoading(false);
                    setSuccess(true);
                },
                (error) => {
                    setStatus(error);
                },
            );
    };

    return (
        <Container>
            <TwoColumn>
                <ImageColumn>
                    <Image imageSrc={EmailIllustrationSrc}/>
                </ImageColumn>
                <TextColumn textOnLeft={textOnLeft}>
                    <TextContent>
                        {subheading && <Subheading>{subheading}</Subheading>}
                        <Heading>{heading}</Heading>
                        {description && <Description>{description}</Description>}
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Input
                                {...register('email', {required: true})}
                                type="email"
                                placeholder="Epost..."
                                disabled={success}
                                name="email"
                            />
                            <p
                                style={{
                                    color: 'red',
                                    marginTop: '6px',
                                }}
                            >
                                {errors.email?.type === 'required' && 'Epost krävs'}
                            </p>
                            <Input
                                disabled={success}
                                type="text"
                                name="name"
                                placeholder="Fullständiga namn..."
                                {...register('name', {required: true})}
                            />
                            <p
                                style={{
                                    color: 'red',
                                    marginTop: '6px',
                                }}
                            >
                                {errors.name?.type === 'required' && 'Namn krävs'}
                            </p>

                            <Input
                                disabled={success}
                                type="text"
                                name="subject"
                                placeholder="Reg nummer..."
                                {...register('subject', {required: true})}
                            />
                            <p
                                style={{
                                    color: 'red',
                                    marginTop: '6px',
                                }}
                            >
                                {errors.name?.type === 'required' &&
                                    'Registreringsnummer krävs'}
                            </p>

                            <Input
                                disabled={success}
                                {...register('number', {required: true})}
                                name="number"
                                type="number"
                                placeholder={'Mobilnummer...'}
                            />
                            <p
                                style={{
                                    color: 'red',
                                    marginTop: '6px',
                                }}
                            >
                                {errors.number?.type === 'required' && 'Nummer krävs'}
                            </p>
                            <Textarea
                                disabled={success}
                                name="message"
                                placeholder="Ditt meddelande..."
                                {...register('message', {required: true})}
                            />
                            <p
                                style={{
                                    color: 'red',
                                    marginTop: '6px',
                                }}
                            >
                                {errors.message?.type === 'required' &&
                                    'Meddelande gällande vad ni behöver krävs'}
                            </p>
                            <SubmitButton disabled={success} type="submit">
                                {isLoading ? (
                                    <Spinner/>
                                ) : success ? (
                                    'Vi hör av oss!'
                                ) : (
                                    'Skicka'
                                )}
                            </SubmitButton>
                        </Form>
                    </TextContent>
                </TextColumn>
            </TwoColumn>
            <ContactContainer>
                <ContactInnerContainer>
                    <ContactDetails>
                        <ContactHeading>Öppettider</ContactHeading>
                        <ContactText>
                            Välkomna till RCD Bil
                            <ul>
                                <BulletPoint>Måndag - Torsdag: 10:00 - 18:00</BulletPoint>
                                <BulletPoint>Fredag: 10:00 - 17:00</BulletPoint>
                                <BulletPoint>Lördag: 11:00 - 15:00</BulletPoint>
                                <BulletPoint>Söndag: 12:00 - 15:00</BulletPoint>
                            </ul>
                        </ContactText>
                        <Avvikande>Avvikande öppettider</Avvikande>
                        <List>
                            <ListItem>24 December: Stängt</ListItem>
                            <ListItem>25 December: Stängt</ListItem>
                            <ListItem>26 December: Stängt</ListItem>
                            <ListItem>31 December: Stängt</ListItem>
                            <ListItem>1 Januari: Stängt</ListItem>
                            <ListItem>Skärtorsdagen: 11:00 - 15:00</ListItem>
                            <ListItem>Långfredagen: 11:00 - 15:00</ListItem>
                            <ListItem>Påskafton: 11:00 - 15:00</ListItem>
                            <ListItem>Påskdagen: 11:00 - 15:00</ListItem>
                            <ListItem>Annandag påsk: 11:00 - 15:00</ListItem>
                        </List>
                        <ContactSection>
                            <ContactInfo>Adress</ContactInfo>
                            <ContactAddress>
                                Västbergavägen 24, 126 30 Hägersten.
                            </ContactAddress>

                            <ContactInfo>Kontaktinformation</ContactInfo>
                            <p>Växel: 08 411 55 55</p>
                            <ContactLink>Email: info@rcdbil.se</ContactLink>
                        </ContactSection>
                    </ContactDetails>
                </ContactInnerContainer>
            </ContactContainer>
        </Container>
    );
};
