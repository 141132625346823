import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro';
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts.js';
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from 'components/misc/Headings';
import { SectionDescription } from 'components/misc/Typography';
import { Avatar, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`text-primary-500`;
const Subheading = tw(SubheadingBase)`text-center mb-3 text-primary-500`;
const Description = tw(
  SectionDescription,
)`mx-auto text-center text-primary-500`;

const Content2xlWrapper = styled(ContentWithPaddingXl)``;
const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto gap-20`;
const Card = tw.div`mt-24 w-full sm:w-1/2 lg:w-1/3 flex flex-col items-center`;
const CardImage = styled.div`
  ${(props) =>
    css`
      background-image: url('${props.imageSrc}');
    `}
  ${tw`w-64 h-64 bg-contain bg-center rounded`}
`;
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-primary-500`}
  }

  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
`;

const CardLinks = styled.div`
  ${tw`mt-6 flex`}
  .link {
    ${tw`mr-8 last:mr-0 text-gray-400 hocus:text-primary-500 transition duration-300`}
    .icon {
      ${tw`fill-current w-6 h-6`}
    }
  }
`;

export default ({
  heading = 'Det här är vi!',
  subheading = 'Vårt Team',
  description = 'På RCD Bil strävar vi efter att erbjuda bästa möjliga service. Vårt engagerade team är här för att hjälpa dig att hitta rätt bil för dina behov och din budget.',
}) => {
  return (
    <Container>
      <Content2xlWrapper>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && <Heading>{heading}</Heading>}
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <Cards>
          <Card>
            <Space
              direction={'horizontal'}
              wrap
              size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
            >
              <Avatar shape="square" size={120} icon={<UserOutlined />} />
            </Space>
            <CardContent>
              <span className="position">Inköp</span>
              <span className="name">Rebaz Diliwi</span>
              <span className="phone">Telefon: 073 500 28 28</span>
              <span className="email">Email: rebaz@rcdbil.se</span>
            </CardContent>
          </Card>
          <Card>
            <Space
              direction={'horizontal'}
              wrap
              size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
            >
              <Avatar shape="square" size={120} icon={<UserOutlined />} />
            </Space>
            <CardContent>
              <span className="position">Försäljning</span>
              <span className="name">Albin Hellström</span>
              <span className="phone">Telefon: 070 665 28 28</span>
              <span className="email">Email: albin@rcdbil.se</span>
            </CardContent>
          </Card>
        </Cards>
      </Content2xlWrapper>
    </Container>
  );
};
