import React from 'react';
import { MessageCircle } from 'lucide-react';

export function FloatingChatButton() {
  const openChat = () => {
    // Wait for Crisp to be fully loaded
    if (typeof window.$crisp !== 'undefined') {
      window.$crisp.push(['do', 'chat:show']);
      window.$crisp.push(['do', 'chat:open']);
    }
  };

  return (
    <button
      onClick={openChat}
      className="fixed bottom-6 right-6 bg-blue-600 text-white p-4 rounded-full shadow-lg cursor-pointer hover:bg-blue-700 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      aria-label="Open chat"
    >
      <MessageCircle size={24} />
    </button>
  );
}
