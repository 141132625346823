export const years = Array.from({length: new Date().getFullYear() - 1949}, (_, i) => 1950 + i);
export const colors = ['röd', 'blå', 'svart', 'vit', 'grå', 'grön', 'gul', 'lila', 'rosa', 'orange'];
export const bodyTypes = ['coupé', 'halvkombi', 'SUV', 'familjebuss', 'yrkesfordon', 'cab', 'kombi', 'småbil', 'sedan'];
export const fuelTypes = ['Bensin', 'Diesel', 'El', 'Miljöbränsle/Hybrid'];
export const brands = [
    'Acura',
    'Alfa Romeo',
    'Aston Martin',
    'Audi',
    'Bentley',
    'BMW',
    'Bugatti',
    'Buick',
    'Cadillac',
    'Chevrolet',
    'Chrysler',
    'Citroën',
    'Dodge',
    'Ferrari',
    'Fiat',
    'Ford',
    'Geely',
    'Genesis',
    'GMC',
    'Honda',
    'Hyundai',
    'Infiniti',
    'Jaguar',
    'Jeep',
    'Kia',
    'Koenigsegg',
    'Lamborghini',
    'Land Rover',
    'Lexus',
    'Lincoln',
    'Lotus',
    'Maserati',
    'Mazda',
    'McLaren',
    'Mercedes-Benz',
    'Mini',
    'Mitsubishi',
    'Nissan',
    'Pagani',
    'Peugeot',
    'Porsche',
    'Ram',
    'Renault',
    'Rolls Royce',
    'Saab',
    'Skoda',
    'Subaru',
    'Suzuki',
    'Tesla',
    'Toyota',
    'Volkswagen',
    'Volvo',
];


export const cars = [
    {
        "title": "Volvo V90 1.6 TDI DSG DIESELVÄRMARE DRAGKROK FULLSERVAD",
        "id": "16970773",
        "publishedDate": "2023-05-24T17:59:40+00:00",
        "createDate": "2023-05-25T12:41:27+00:00",
        "permalink": "https://rcdbil.accesspaket.bytbilcms.com/bil/skoda-octavia-1-6-tdi-dsg-dieselvarmare-dragkrok-fullservad/",
        "data": {
            "enginePower": 81,
            "gearBox": "Automatisk",
            "fuel": "Diesel",
            "co2Emissions": null,
            "isEco": false,
            "id": 16970771,
            "vehicleType": "car",
            "bodyType": "Kombi",
            "color": "Vit",
            "freetextColor": "Vit",
            "make": "Skoda",
            "model": "Octavia",
            "isModelApproved": true,
            "modelGroup": null,
            "modelRaw": "Octavia 1.6 TDI DSG DIESELVÄRMARE DRAGKROK FULLSERVAD",
            "equipment": [
                "12V-uttag",
                "230V-uttag",
                "ABS-bromsar",
                "AC",
                "Airbag förare",
                "Airbag passagerare fram",
                "Antisladd",
                "Antispinn",
                "Autobroms",
                "AUX-ingång",
                "Avstängningsbar airbag",
                "Backstartshjälp",
                "Barnlås",
                "Centrallås (fjärrstyrt)",
                "Delbart baksäte",
                "Dragkrok (avtagbar)",
                "Elhissar (Fram och Bak)",
                "Elhissar (Fram)",
                "Eluppvärmda sidospeglar",
                "Euro 6",
                "Farthållare",
                "Front assist",
                "Färddator",
                "Isofix fästen",
                "Klädsel (tyg)",
                "Läslampa",
                "Motorvärmare fjärrstyrd",
                "Rails",
                "Regnsensor",
                "Servostyrning",
                "Sidoairbags",
                "Sidokrockgardiner",
                "Sminkspegel",
                "Start-/stoppfunktion",
                "Startspärr",
                "Svensksåld",
                "Sätesvärme (fram)",
                "Touch-/Pekskärm",
                "Trötthetsvarnare",
                "Trötthetsvarnare",
                "Uppvärmda spolare",
                "USB-uttag",
                "Yttertemperaturmätare"
            ],
            "description": "Är ni ute efter den perfekta pendlarbilen så har ni hittat helt rätt. Här har vi en fin Volvo V90 med endast 1 brukare på nacken som tagit hand om bilen väl. Det här är en bil som passar lika bra att köra i storstan som på landet. Ni har en euro 6 motor som levererar 110 dieselkrafter som räcker gott och väl. Samtliga passagerare sitter bekvämt med gott om utrymme och bilen tar er dit ni ska utan några som helst konstigheter. Den är otroligt billig att äga samt att köra då ni har en ynka årlig skatt på 1103:- och har en blandad förbrukning på 0.37 L/MIL - detta är i princip billigare än att äga ett busskort i dagsläget. I övrigt en fin och fräsch bil där ni har dieselvärmare som ser till att bilen blir varm på vintrarna samt infällbar så ni kan lasta på en massa vid behov. <br/><br/>Besiktigad till och med 2024-02-29 och fullservad på auktoriserad verkstad vid miltal: 814 - 3135 - 4045 - 5578 - 6709 - 8209 - 9100 - 11 038 och levereras ut helt nyservad. Endast sommardäck medföljer i priset samt 2 st nycklar + fjärr till värmaren. Vi erbjuder 4 veckors prova på helförsäkring via Länsförsäkringar. Finansiering samt garantier kan ordnas på samtliga bilar i hallen och självklart tar vi emot din bil i inbyte. Kontakta oss på RCD BIL för mer information. Varmt välkomna in.",
            "modelYear": 2017,
            "regNo": "FMB371",
            "regDate": "2016-10-31T00:00:00",
            "regNoHidden": false,
            "hasImage": true,
            "isNew": false,
            "images": [
                {
                    "sortOrder": 1,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/f4/f44aff0f-3116-47db-9c2b-77ce615023b3?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/f4/f44aff0f-3116-47db-9c2b-77ce615023b3?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 2,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/ce/ced8400a-2ece-4d72-a114-d798a073ac9c?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/ce/ced8400a-2ece-4d72-a114-d798a073ac9c?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 3,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/54/5424c9cc-6ce3-49a7-b78a-a7c321c20b64?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/54/5424c9cc-6ce3-49a7-b78a-a7c321c20b64?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 4,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/b9/b9bf38b8-f434-448a-9c48-35d3a9866494?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/b9/b9bf38b8-f434-448a-9c48-35d3a9866494?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 5,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/14/145f6e0a-ace7-43a8-958b-dc35c615c5e8?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/14/145f6e0a-ace7-43a8-958b-dc35c615c5e8?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 6,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/84/8484691c-4193-45d4-8233-06ba70e90541?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/84/8484691c-4193-45d4-8233-06ba70e90541?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 7,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/0b/0bc6da66-2ac7-40b7-adc3-3c3c492d8cff?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/0b/0bc6da66-2ac7-40b7-adc3-3c3c492d8cff?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 8,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/ac/acd8cce1-614d-4aa2-8c7d-4ce7f7c9b2ed?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/ac/acd8cce1-614d-4aa2-8c7d-4ce7f7c9b2ed?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 9,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/4d/4d7abced-c15c-4af1-b7e4-f4cb12dcc06f?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/4d/4d7abced-c15c-4af1-b7e4-f4cb12dcc06f?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 10,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/d1/d1c64345-fbab-465a-9b70-02a02376eff7?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/d1/d1c64345-fbab-465a-9b70-02a02376eff7?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 11,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/f7/f7deb904-c8cf-44a0-b038-d674a2092d8b?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/f7/f7deb904-c8cf-44a0-b038-d674a2092d8b?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 12,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/5b/5b1cde58-75d7-459e-9571-d0f91628e4cd?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/5b/5b1cde58-75d7-459e-9571-d0f91628e4cd?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 13,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/26/2657a7f3-c09c-4cc8-9ae2-8e092a26b485?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/26/2657a7f3-c09c-4cc8-9ae2-8e092a26b485?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 14,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/a3/a32a07d6-8299-4ba9-a84e-7e7f00621ffd?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/a3/a32a07d6-8299-4ba9-a84e-7e7f00621ffd?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 15,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/f4/f48a8e59-fb17-40d2-8976-47afa69a98c6?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/f4/f48a8e59-fb17-40d2-8976-47afa69a98c6?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 16,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/a6/a6740e5d-2a8e-474f-8d00-2e8167df33d3?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/a6/a6740e5d-2a8e-474f-8d00-2e8167df33d3?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 17,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/3a/3abed6b7-95e2-488b-9b3e-30eb42bba48e?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/3a/3abed6b7-95e2-488b-9b3e-30eb42bba48e?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 18,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/de/deb84623-26dd-4d45-abda-696ace2040ac?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/de/deb84623-26dd-4d45-abda-696ace2040ac?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 19,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/9f/9fda7960-963d-4944-90d1-efb49b223c41?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/9f/9fda7960-963d-4944-90d1-efb49b223c41?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                }
            ],
            "warrantyProgram": null,
            "inWarrantyProgram": false,
            "price": {
                "value": 144900,
                "previousValue": null,
                "hasChanged": false,
                "isAuction": false,
                "isLeasing": false,
                "showExcludingVat": false
            },
            "milage": 13946,
            "dealer": {
                "name": "RCD Bil AB",
                "address": {
                    "streetAddress": "Västbergavägen 24",
                    "zipcode": 12630,
                    "city": "HÄGERSTEN",
                    "region": "Stockholms län"
                },
                "authorizedMakes": [],
                "accountManagers": [
                    {
                        "id": null,
                        "name": "Rebaz Diliwi",
                        "phoneNumber": 735002828,
                        "mobilePhoneNumber": "",
                        "emailAddresses": [
                            "rebaz@rcdbil.se"
                        ],
                        "image": null,
                        "website": null
                    },
                    {
                        "id": null,
                        "name": "Albin Hellström",
                        "phoneNumber": 706652828,
                        "mobilePhoneNumber": "",
                        "emailAddresses": [
                            "albin@rcdbil.se"
                        ],
                        "image": null,
                        "website": null
                    }
                ],
                "id": 8795,
                "emailAddresses": [
                    "info@rcdbil.se"
                ],
                "emailAddressesInterest": [
                    "info@rcdbil.se"
                ],
                "website": "http://www.rcdbil.se",
                "phoneNumber": 84115555,
                "mobilePhoneNumber": null,
                "customerCode": "rcdbil",
                "message": "Varmt välkomna till RCD BIL AB. Hos oss hittar ni service i världsklass. Som kund är du alltid i största fokus. Vi skapar tillit genom ärlighet och försöker alltid uppnå våra kunders förväntningar. Med massa erfarenhet i nacken hittar vi alltid en skräddarsydd lösning till er som passar era behov bäst.r<br/>Våra bilar går självklart igenom en kvalitetskontroll innan försäljning och du som kund har tillgång till en kostnadsfri Carfax rapport så att ni känner er så trygga som möjligt. Vi erbjuder förmånlig finansiering i form av avbetalning på samtliga bilar i vår hall i samarbete med DNB finans.",
                "isMRFCertified": false
            },
            "accountManager": null,
            "carfaxReport": {
                "reportExists": true,
                "eventsCount": 11,
                "clickUrl": "https://api.bytbil.com/carfax-proxy/v1/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2aW5yZWciOiJGTUIzNzEifQ.Eb7fp9HwVFGDFiPnORQFyYsex7FzIBkT01MKS1bj-c8",
                "error": "",
                "impressionUrl": null,
                "label": "Gratis rapport"
            },
            "additionalVehicleData": null,
            "beds": null,
            "length": null,
            "engineSize": null,
            "enginetype": null,
            "engineHours": null,
            "cargoLength": null,
            "cargoHeight": null,
            "cargoWidth": null,
            "totalWeight": null,
            "usedState": "old",
            "currentPrice": 144900,
            "city": "Hägersten",
            "dealerName": "RCD Bil AB",
            "thumbnailUrl": "https://pro.bbcdn.io/f4/f44aff0f-3116-47db-9c2b-77ce615023b3?rule=legacy-largest",
            "leasing": 0,
            "towBar": true
        }
    },
    {
        "title": "Skoda Octavia 1.6 TDI DSG DIESELVÄRMARE DRAGKROK FULLSERVAD",
        "id": "16970771",
        "publishedDate": "2023-05-24T17:59:40+00:00",
        "createDate": "2023-05-25T12:41:27+00:00",
        "permalink": "https://rcdbil.accesspaket.bytbilcms.com/bil/skoda-octavia-1-6-tdi-dsg-dieselvarmare-dragkrok-fullservad/",
        "data": {
            "enginePower": 81,
            "gearBox": "Automatisk",
            "fuel": "Diesel",
            "co2Emissions": null,
            "isEco": false,
            "id": 16970771,
            "vehicleType": "car",
            "bodyType": "Småbil",
            "color": "Vit",
            "freetextColor": "Vit",
            "make": "Alfa Romeo",
            "model": "Octavia",
            "isModelApproved": true,
            "modelGroup": null,
            "modelRaw": "Octavia 1.6 TDI DSG DIESELVÄRMARE DRAGKROK FULLSERVAD",
            "equipment": [
                "12V-uttag",
                "230V-uttag",
                "ABS-bromsar",
                "AC",
                "Airbag förare",
                "Airbag passagerare fram",
                "Antisladd",
                "Antispinn",
                "Autobroms",
                "AUX-ingång",
                "Avstängningsbar airbag",
                "Backstartshjälp",
                "Barnlås",
                "Centrallås (fjärrstyrt)",
                "Delbart baksäte",
                "Dragkrok (avtagbar)",
                "Elhissar (Fram och Bak)",
                "Elhissar (Fram)",
                "Eluppvärmda sidospeglar",
                "Euro 6",
                "Farthållare",
                "Front assist",
                "Färddator",
                "Isofix fästen",
                "Klädsel (tyg)",
                "Läslampa",
                "Motorvärmare fjärrstyrd",
                "Rails",
                "Regnsensor",
                "Servostyrning",
                "Sidoairbags",
                "Sidokrockgardiner",
                "Sminkspegel",
                "Start-/stoppfunktion",
                "Startspärr",
                "Svensksåld",
                "Sätesvärme (fram)",
                "Touch-/Pekskärm",
                "Trötthetsvarnare",
                "Trötthetsvarnare",
                "Uppvärmda spolare",
                "USB-uttag",
                "Yttertemperaturmätare"
            ],
            "description": "Är ni ute efter den perfekta pendlarbilen så har ni hittat helt rätt. Här har vi en fin Skoda Octavia med endast 1 brukare på nacken som tagit hand om bilen väl. Det här är en bil som passar lika bra att köra i storstan som på landet. Ni har en euro 6 motor som levererar 110 dieselkrafter som räcker gott och väl. Samtliga passagerare sitter bekvämt med gott om utrymme och bilen tar er dit ni ska utan några som helst konstigheter. Den är otroligt billig att äga samt att köra då ni har en ynka årlig skatt på 1103:- och har en blandad förbrukning på 0.37 L/MIL - detta är i princip billigare än att äga ett busskort i dagsläget. I övrigt en fin och fräsch bil där ni har dieselvärmare som ser till att bilen blir varm på vintrarna samt infällbar dragkrok så ni kan lasta på en massa vid behov. <br/><br/>Besiktigad till och med 2024-02-29 och fullservad på auktoriserad verkstad vid miltal: 814 - 3135 - 4045 - 5578 - 6709 - 8209 - 9100 - 11 038 och levereras ut helt nyservad. Endast sommardäck medföljer i priset samt 2 st nycklar + fjärr till värmaren. Vi erbjuder 4 veckors prova på helförsäkring via Länsförsäkringar. Finansiering samt garantier kan ordnas på samtliga bilar i hallen och självklart tar vi emot din bil i inbyte. Kontakta oss på RCD BIL för mer information. Varmt välkomna in.",
            "modelYear": 2017,
            "regNo": "FMB371",
            "regDate": "2016-10-31T00:00:00",
            "regNoHidden": false,
            "hasImage": true,
            "isNew": false,
            "images": [
                {
                    "sortOrder": 1,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/f4/f44aff0f-3116-47db-9c2b-77ce615023b3?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/f4/f44aff0f-3116-47db-9c2b-77ce615023b3?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 2,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/ce/ced8400a-2ece-4d72-a114-d798a073ac9c?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/ce/ced8400a-2ece-4d72-a114-d798a073ac9c?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 3,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/54/5424c9cc-6ce3-49a7-b78a-a7c321c20b64?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/54/5424c9cc-6ce3-49a7-b78a-a7c321c20b64?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 4,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/b9/b9bf38b8-f434-448a-9c48-35d3a9866494?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/b9/b9bf38b8-f434-448a-9c48-35d3a9866494?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 5,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/14/145f6e0a-ace7-43a8-958b-dc35c615c5e8?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/14/145f6e0a-ace7-43a8-958b-dc35c615c5e8?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 6,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/84/8484691c-4193-45d4-8233-06ba70e90541?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/84/8484691c-4193-45d4-8233-06ba70e90541?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 7,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/0b/0bc6da66-2ac7-40b7-adc3-3c3c492d8cff?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/0b/0bc6da66-2ac7-40b7-adc3-3c3c492d8cff?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 8,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/ac/acd8cce1-614d-4aa2-8c7d-4ce7f7c9b2ed?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/ac/acd8cce1-614d-4aa2-8c7d-4ce7f7c9b2ed?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 9,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/4d/4d7abced-c15c-4af1-b7e4-f4cb12dcc06f?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/4d/4d7abced-c15c-4af1-b7e4-f4cb12dcc06f?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 10,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/d1/d1c64345-fbab-465a-9b70-02a02376eff7?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/d1/d1c64345-fbab-465a-9b70-02a02376eff7?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 11,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/f7/f7deb904-c8cf-44a0-b038-d674a2092d8b?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/f7/f7deb904-c8cf-44a0-b038-d674a2092d8b?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 12,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/5b/5b1cde58-75d7-459e-9571-d0f91628e4cd?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/5b/5b1cde58-75d7-459e-9571-d0f91628e4cd?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 13,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/26/2657a7f3-c09c-4cc8-9ae2-8e092a26b485?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/26/2657a7f3-c09c-4cc8-9ae2-8e092a26b485?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 14,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/a3/a32a07d6-8299-4ba9-a84e-7e7f00621ffd?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/a3/a32a07d6-8299-4ba9-a84e-7e7f00621ffd?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 15,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/f4/f48a8e59-fb17-40d2-8976-47afa69a98c6?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/f4/f48a8e59-fb17-40d2-8976-47afa69a98c6?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 16,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/a6/a6740e5d-2a8e-474f-8d00-2e8167df33d3?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/a6/a6740e5d-2a8e-474f-8d00-2e8167df33d3?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 17,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/3a/3abed6b7-95e2-488b-9b3e-30eb42bba48e?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/3a/3abed6b7-95e2-488b-9b3e-30eb42bba48e?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 18,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/de/deb84623-26dd-4d45-abda-696ace2040ac?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/de/deb84623-26dd-4d45-abda-696ace2040ac?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                },
                {
                    "sortOrder": 19,
                    "largeFormatExists": true,
                    "imageFormats": [
                        {
                            "url": "https://pro.bbcdn.io/9f/9fda7960-963d-4944-90d1-efb49b223c41?rule=legacy-largest",
                            "width": 0,
                            "height": 0,
                            "name": "main"
                        },
                        {
                            "url": "https://pro.bbcdn.io/9f/9fda7960-963d-4944-90d1-efb49b223c41?rule=legacy-main",
                            "width": 0,
                            "height": 0,
                            "name": "thumb"
                        }
                    ]
                }
            ],
            "warrantyProgram": null,
            "inWarrantyProgram": false,
            "price": {
                "value": 144900,
                "previousValue": null,
                "hasChanged": false,
                "isAuction": false,
                "isLeasing": false,
                "showExcludingVat": false
            },
            "milage": 13946,
            "dealer": {
                "name": "RCD Bil AB",
                "address": {
                    "streetAddress": "Västbergavägen 24",
                    "zipcode": 12630,
                    "city": "HÄGERSTEN",
                    "region": "Stockholms län"
                },
                "authorizedMakes": [],
                "accountManagers": [
                    {
                        "id": null,
                        "name": "Rebaz Diliwi",
                        "phoneNumber": 735002828,
                        "mobilePhoneNumber": "",
                        "emailAddresses": [
                            "rebaz@rcdbil.se"
                        ],
                        "image": null,
                        "website": null
                    },
                    {
                        "id": null,
                        "name": "Albin Hellström",
                        "phoneNumber": 706652828,
                        "mobilePhoneNumber": "",
                        "emailAddresses": [
                            "albin@rcdbil.se"
                        ],
                        "image": null,
                        "website": null
                    }
                ],
                "id": 8795,
                "emailAddresses": [
                    "info@rcdbil.se"
                ],
                "emailAddressesInterest": [
                    "info@rcdbil.se"
                ],
                "website": "http://www.rcdbil.se",
                "phoneNumber": 84115555,
                "mobilePhoneNumber": null,
                "customerCode": "rcdbil",
                "message": "Varmt välkomna till RCD BIL AB. Hos oss hittar ni service i världsklass. Som kund är du alltid i största fokus. Vi skapar tillit genom ärlighet och försöker alltid uppnå våra kunders förväntningar. Med massa erfarenhet i nacken hittar vi alltid en skräddarsydd lösning till er som passar era behov bäst.r<br/>Våra bilar går självklart igenom en kvalitetskontroll innan försäljning och du som kund har tillgång till en kostnadsfri Carfax rapport så att ni känner er så trygga som möjligt. Vi erbjuder förmånlig finansiering i form av avbetalning på samtliga bilar i vår hall i samarbete med DNB finans.",
                "isMRFCertified": false
            },
            "accountManager": null,
            "carfaxReport": {
                "reportExists": true,
                "eventsCount": 11,
                "clickUrl": "https://api.bytbil.com/carfax-proxy/v1/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2aW5yZWciOiJGTUIzNzEifQ.Eb7fp9HwVFGDFiPnORQFyYsex7FzIBkT01MKS1bj-c8",
                "error": "",
                "impressionUrl": null,
                "label": "Gratis rapport"
            },
            "additionalVehicleData": null,
            "beds": null,
            "length": null,
            "engineSize": null,
            "enginetype": null,
            "engineHours": null,
            "cargoLength": null,
            "cargoHeight": null,
            "cargoWidth": null,
            "totalWeight": null,
            "usedState": "old",
            "currentPrice": 144900,
            "city": "Hägersten",
            "dealerName": "RCD Bil AB",
            "thumbnailUrl": "https://pro.bbcdn.io/f4/f44aff0f-3116-47db-9c2b-77ce615023b3?rule=legacy-largest",
            "leasing": 0,
            "towBar": true
        }
    }
]