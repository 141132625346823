import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { brands } from '../cars/constants';
import styled from 'styled-components';

const Form = styled.form`
  display: grid;
  //grid-template-columns: 11fr 1fr;
  grid-template-columns: 1fr; /* Update grid template columns for mobile */
  gap: 12px;
  width: 100%;
  max-width: 1280px;
  padding: 2em 0;
  @media (max-width: 768px) {
    justify-content: center; /* Center the elements on mobile */
  }
`;

const LeftSide = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const RightSide = styled.div`
  display: grid;
  //grid-template-columns: 7fr 1fr;
  gap: 12px;

  @media (min-width: 769px) {
    /* Add media query to adjust grid template columns for desktop */
    grid-template-columns: 7fr 1fr;
  }
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
`;

const Error = styled.p`
  color: red;
  width: 100%;
  max-width: 1280px;

  @media (min-width: 1200px) {
    grid-column: span 4;
  }
`;

const SubmitButton = styled.button`
  padding: 10px;
  border: none;
  background-color: #a0aec0;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  grid-column: span 4; /* Make the submit button span all 4 columns */
  height: 60px;
  width: 200px;
  @media (max-width: 768px) {
    width: 100%;
  }

  &:hover {
    background-color: #243e63;
  }
`;

const CustomFilter = ({
  data,
  years,
  colors,
  bodyTypes,
  fuelTypes,
  brands,
  onFilter,
}) => {
  const { register, handleSubmit } = useForm();
  const [errorMessage, setErrorMessage] = useState(null);

  const onSubmit = (input) => {
    try {
      let result = [...data];

      if (input.vehicleType) {
        result = result.filter(
          (v) =>
            v.data.bodyType.toLowerCase() === input.vehicleType.toLowerCase(),
        );
      }

      if (input.brands) {
        result = result.filter(
          (v) => v.data.make.toLowerCase() === input.brands.toLowerCase(),
        );
      }

      if (input.mileageFrom && input.mileageTo) {
        const from = Number(input.mileageFrom);
        const to = Number(input.mileageTo);
        if (from > to)
          throw new Error('Mileage From must be less than Mileage To');
        result = result.filter(
          (v) => v.data.milage >= from && v.data.milage <= to,
        );
      }

      if (input.yearFrom && input.yearTo) {
        const from = Number(input.yearFrom);
        const to = Number(input.yearTo);
        if (from > to) throw new Error('Year From must be less than Year To');
        result = result.filter(
          (v) => v.data.modelYear >= from && v.data.modelYear <= to,
        );
      }

      if (input.costFrom && input.costTo) {
        const from = Number(input.costFrom);
        const to = Number(input.costTo);
        if (from > to) throw new Error('Cost From must be less than Cost To');
        result = result.filter(
          (v) => v.data.currentPrice >= from && v.data.currentPrice <= to,
        );
      }

      if (input.color) {
        result = result.filter(
          (v) => v.data.color.toLowerCase() === input.color.toLowerCase(),
        );
      }

      if (input.fuel) {
        result = result.filter(
          (v) => v.data.fuel.toLowerCase() === input.fuel.toLowerCase(),
        );
      }

      if (input.freeText) {
        result = result.filter(
          (v) =>
            v.data.description &&
            v.data.description
              .toString()
              .toLowerCase()
              .includes(input.freeText.toLowerCase()),
        );
      }

      onFilter(result); // Pass the filtered result to the parent component
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <LeftSide>
          <label>
            Pris
            <Input as={'select'} {...register('costFrom')}>
              <option value="">Från</option>
              {[...Array(201)].map((_, i) => (
                <option value={i * 10000}>{i * 10000}</option>
              ))}
            </Input>
            <Input as={'select'} {...register('costTo')}>
              <option value="">Till</option>
              {[...Array(201)].map((_, i) => (
                <option value={i * 10000}>{i * 10000}</option>
              ))}
            </Input>
          </label>
          <label>
            Modellår
            <Input as={'select'} {...register('yearFrom')}>
              <option value="">Från</option>
              {years.map((year) => (
                <option value={year}>{year}</option>
              ))}
            </Input>
            <Input as={'select'} {...register('yearTo')}>
              <option value="">Till</option>
              {years.map((year) => (
                <option value={year}>{year}</option>
              ))}
            </Input>
          </label>
          <label>
            Miltal
            <Input as={'select'} {...register('mileageFrom')}>
              <option value="">Från</option>
              {[...Array(51)].map((_, i) => (
                <option value={i * 500}>{i * 500}</option>
              ))}
            </Input>
            <Input as={'select'} {...register('mileageTo')}>
              <option value="">Till</option>
              {[...Array(51)].map((_, i) => (
                <option value={i * 500}>{i * 500}</option>
              ))}
            </Input>
          </label>
          <label>
            Märke
            <Input as={'select'} {...register('brands')}>
              <option value="">Alla</option>
              {brands.map((brand) => (
                <option value={brand}>{brand}</option>
              ))}
            </Input>
          </label>
          <label>
            Biltyp
            <Input as={'select'} {...register('vehicleType')}>
              <option value="">Alla</option>
              {bodyTypes.map((type) => (
                <option value={type}>{type}</option>
              ))}
            </Input>
          </label>
          <label>
            Färg
            <Input as={'select'} {...register('color')}>
              <option value="">Alla</option>
              {colors.map((color) => (
                <option value={color}>{color}</option>
              ))}
            </Input>
          </label>
          <label>
            Drivmedel
            <Input as={'select'} {...register('fuel')}>
              <option value="">Alla</option>
              {fuelTypes.map((type) => (
                <option value={type}>{type}</option>
              ))}
            </Input>
          </label>
          <label>
            Fritext
            <Input {...register('freeText')} placeholder="Fritext sökning..." />
          </label>
        </LeftSide>

        <RightSide>
          <SubmitButton type="submit" value="Submit">
            Sök
          </SubmitButton>
        </RightSide>
      </Form>
      {errorMessage && <Error>Error: {errorMessage}</Error>}
    </>
  );
};

export default CustomFilter;
