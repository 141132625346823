import React from 'react';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import HeaderBase, {
  NavLinks,
  NavLink as NavLinkBase,
  PrimaryLink as PrimaryLinkBase,
  DesktopNavLinks,
  LogoLink,
  NavToggle,
} from '../headers/light.js';
import { Content2Xl } from 'components/misc/Layouts.js';
import styled from 'styled-components';
import CustomFilter from '../CustomFilter';

const PrimaryBackgroundContainer = tw.div`-mx-8 px-8`;
const NavLink = tw(
  NavLinkBase,
)`lg:text-gray-100 lg:hocus:text-gray-300 lg:hocus:border-gray-100`;
const StyledHeader = styled(HeaderBase)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLinkBase}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }

  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url('/images/test.jpeg');
  height: 50vh;
  @media (max-width: 768px) {
    height: 30vh;
  }
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-15`;
const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const LeftColumn = tw.div`flex flex-col items-start lg:block sm:mt-24`;
const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium text-sm md:text-3xl lg:text-4xl md:w-1/2`;

export default ({
  data,
  years,
  colors,
  bodyTypes,
  fuelTypes,
  brands,
  onFilter,
}) => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/">Hem</NavLink>
      <NavLink href="/contact">Sälja bil</NavLink>
      <NavLink href="/about">Om oss</NavLink>
      <PrimaryLinkBase href="/contact">Kontakt</PrimaryLinkBase>
    </NavLinks>,
  ];
  return (
    <PrimaryBackgroundContainer>
      <Container>
        <OpacityOverlay />
        <HeroContainer>
          <StyledHeader links={navLinks} />
          <LeftColumn>
            <Notification>
              Hos RCD Bil erbjuder vi dig en säker bilhandelsupplevelse.
            </Notification>
          </LeftColumn>
        </HeroContainer>
      </Container>
      <Content2Xl>
        <CustomFilter
          data={data}
          years={years}
          colors={colors}
          bodyTypes={bodyTypes}
          fuelTypes={fuelTypes}
          brands={brands}
          onFilter={onFilter}
        />
      </Content2Xl>
    </PrimaryBackgroundContainer>
  );
};
